import { useTranslation } from 'next-i18next'
import { css } from '@emotion/react'

import Image from '@/components/common/Image'
import { mediaUp } from '@/styles/media'

function PartnersPl() {
  const { t } = useTranslation('common')

  return (
    <>
      <li css={partner}>
        <img
          src={'https://cdn.naviparking.com/digital_parking/img/p-24.webp'}
          alt={t('partner.przelewy24')}
        />
      </li>
      <li css={partner}>
        <img
          src={'https://cdn.naviparking.com/digital_parking/img/blik.webp'}
          alt={t('partner.blik')}
        />
      </li>
      <li css={partner}>
        <img
          src={'https://cdn.naviparking.com/digital_parking/img/visa.webp'}
          alt="partner.visa"
        />
      </li>
      <li css={partner}>
        <img
          src={'https://cdn.naviparking.com/digital_parking/img/mc.webp'}
          alt={t('partner.masterCard')}
        />
      </li>
      <li css={partner}>
        <img
          src={'https://cdn.naviparking.com/digital_parking/img/play.webp'}
          alt={t('partner.play')}
        />
      </li>
      <li css={partner}>
        <img
          src={'https://cdn.naviparking.com/digital_parking/img/orange.webp'}
          alt={t('partner.orange')}
        />
      </li>
      <li css={partner}>
        <img
          src={'https://cdn.naviparking.com/digital_parking/img/plus.webp'}
          alt={t('partner.plus')}
        />
      </li>
      <li css={partner}>
        <img
          src={'https://cdn.naviparking.com/digital_parking/img/pcidss.webp'}
          alt={t('partner.pci')}
        />
      </li>
    </>
  )
}

const partner = css`
  position: relative;
  margin: 10px 14px;

  ${mediaUp('md')} {
    margin: 0 14px;
  }

  ${mediaUp('lg')} {
    margin: 0 35px;
  }

  svg {
    max-width: 100%;
    max-height: 100%;
  }

  &:nth-of-type(1) {
    height: 20px;
    width: 57px;

    ${mediaUp('lg')} {
      height: 43px;
      width: 131px;
    }
  }

  &:nth-of-type(2) {
    height: 20px;
    width: 37px;

    ${mediaUp('lg')} {
      height: 43px;
      width: 83px;
    }
  }

  &:nth-of-type(3) {
    height: 16px;
    width: 46px;

    ${mediaUp('lg')} {
      height: 33px;
      width: 104px;
    }
  }

  &:nth-of-type(4) {
    height: 24px;
    width: 32px;

    ${mediaUp('lg')} {
      height: 54px;
      width: 72px;
    }
  }

  &:nth-of-type(5) {
    height: 14px;
    width: 45px;

    ${mediaUp('lg')} {
      height: 32px;
      width: 102px;
    }
  }

  &:nth-of-type(6) {
    height: 28px;
    width: 28px;

    ${mediaUp('lg')} {
      height: 64px;
      width: 64px;
    }
  }

  &:nth-of-type(7) {
    height: 22px;
    width: 45px;

    ${mediaUp('lg')} {
      height: 51px;
      width: 102px;
    }
  }

  &:nth-of-type(8) {
    height: 20px;
    width: 39px;

    ${mediaUp('lg')} {
      height: 44px;
      width: 87px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

export default PartnersPl
